<template>
  <div id="app" class="fullscreen">
    <div class="background-layer" />
    <div v-if="$auth.ready()" class="front-layer">
      <div v-if="$auth.check()" class="front-layer">
        <main-menu />

        <router-view class="fullscreen" v-slot="{ Component }">
          <transition name="fade" mode="out-in">
            <component :is="Component" />
          </transition>
        </router-view>
      </div>
      <login-page v-else />
    </div>
    <spinner-anim v-else />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mainMenu from '@/components/mainMenu'
import spinnerAnim from '@/components/spinnerAnim'
import Login from './views/Login'

export default {
  components: {
    mainMenu,
    spinnerAnim,
    'login-page': Login,
  },
  computed: {
    ...mapState({
      routeName: (state) => state.route.name,
    }),
  },
}
</script>

<style lang="scss">
@import '@/style/base.scss';
.background-layer {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.front-layer {
  z-index: 1;
  position: relative;
}
</style>

<template>
  <div v-if="documento">
    <b>{{ doc.label }}:</b> {{ doc.num }}
  </div>
</template>

<script>
import { formatNumDoc } from '@/utils'

export default {
  name: 'documentoFormatado',
  props: {
    documento: {
      required: true,
    },
  },
  computed: {
    doc: function () {
      return formatNumDoc(this.documento)
    },
  },
}
</script>

<style scoped>
div {
  display: inline-block;
}
</style>

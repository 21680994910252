import { createApp } from 'vue'
import VueAxios from 'vue-axios'
import http from './http'
import { sync } from 'vuex-router-sync'

import App from './App.vue'
import router from './router'
import auth from './auth'
import store from './store'
import i18n from './i18n'

import 'leaflet/dist/leaflet.css'

sync(store, router)

createApp(App)
  .use(VueAxios, http)
  .use(i18n)
  .use(router)
  .use(store)
  .use(auth)
  .mount('#app')

<template>
  <div class="nav-anchor">
    <div v-if="$route.name !== 'login'" id="nav">
      <div class="content-width nav-content">
        <div class="flex-parent-center">
          <img class="logo" src="@/assets/logo-rb.png" />
          <router-link
            :to="{ name: 'home' }"
            v-t="'search'"
            class="bigger-text"
          />
          <!-- <router-link :to="{ name: 'about' }" v-t="'about_website_link'" />
          <router-link :to="{ name: 'ajuda' }" v-t="'tutorial_link'" />
          <router-link :to="{ name: 'rb' }" v-t="'about_us_link'" /> -->
        </div>
        <!-- <div> -->
        <!--   logado como: {{ $auth.user().username }} -->
        <!-- </div> -->
        <div class="flex-parent-center">
          <button
            class="margin-lr text-btn"
            @click="$auth.logout({ makeRequest: false })"
            v-t="'logout'"
          />
          <button class="text-btn" @click="setLang('pt-BR')">pt</button>
          <button class="text-btn" @click="setLang('en')">en</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainMenu',
  data() {
    return {}
  },
  methods: {
    setLang(code) {
      this.$i18n.locale = code
    },
  },
  computed: {},
}
</script>

<style lang="scss">
@import '@/style/vars.scss';
$nav-min-height: 2.5rem;
.nav-anchor {
  min-height: $nav-min-height;
  z-index: 10;
  box-shadow: 0px 0px 10px black;
  z-index: 500;
  position: relative;
}
#nav {
  min-height: $nav-min-height;
  border-top: solid 4px $color-pri;
  display: flex;
  background-color: white;
  box-shadow: 1px 1px 5px #0005;
  a {
    font-size: 14px;
    color: $color-text;
    margin: 0 1rem;
    font-family: opensans-bold;
    &.router-link-exact-active {
      opacity: 0;
    }
    &:hover,
    &:focus {
      color: $color-btn-hover;
    }
  }
}
.bigger-text {
  font-size: 18px !important;
}
.nav-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.margin-lr {
  margin: 0 0.5rem;
}
.logo {
  max-width: 6rem;
}
</style>

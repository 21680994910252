<template>
  <div class="content-width page">
    <h1 v-t="'fiscal.main_title'" />

    <div v-if="!pendings.detail">
      <p>{{ entidade.nome }}</p>

      <h2 v-t="'fiscal.embargos_title'" />
      <div v-if="entidade.embargos.length">
        <exp-table
          :rows="entidade.embargos"
          :formatters="embargosFormatters"
          :cols="embargoCols"
          :extraCols="embargoExtraCols"
        />
      </div>
      <div v-else v-t="'fiscal.no_case_in_db'" />

      <h2 v-t="'fiscal.multas_title'" />
      <div v-if="entidade.multas.length">
        <exp-table
          :rows="entidade.multas"
          :formatters="multaFormatters"
          :cols="multaCols"
          :extraCols="multaExtraCols"
        />
      </div>
      <div v-else v-t="'fiscal.no_case_in_db'" />

      <h2 v-t="'fiscal.trab_escravo_title'" />
      <div v-if="entidade.trab.length">
        <exp-table
          :rows="entidade.trab"
          :formatters="trabFormatters"
          :cols="trabCols"
          :extraCols="trabExtraCols"
        />
      </div>
      <div v-else v-t="'fiscal.no_case_in_db'" />
    </div>
    <spinner-anim v-else />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import expTable from '@/components/expTable'
import spinnerAnim from '@/components/spinnerAnim'

export default {
  name: 'fiscalizacoes',
  components: {
    expTable,
    spinnerAnim,
  },
  data() {
    return {
      multaCols: [
        'data_auto',
        'tipo_infracao',
        'bioma',
        'valor',
        'municipio',
        'uf',
      ], // v-t=array
      multaExtraCols: [
        'num',
        'serie',
        'tipo_auto',
        'descr_auto',
        'nome_infrator',
        'num_doc',
        'quant_area',
        'classificacao_area',
        'descr_local_infracao',
        'descr_outros_tipo_area',
        'descr_infracao',
        'sancoes_aplicadas',
        'infracao_area',
        'sequencia',
      ], // v-t=array
      multaFormatters: {
        data_auto: 'date',
        valor: 'currency',
      },

      embargoCols: [
        'data_embargo',
        'natureza',
        'item',
        'nome_imovel',
        'municipio',
        'uf',
      ], // v-t=array
      embargoExtraCols: [
        'num',
        'serie',
        'num_auto',
        'nome_embargado',
        'num_doc',
        'descr_tad',
        'descr_localizacao',
        'quant_area_embargada',
        'infracao',
        'data_insercao',
        'julgamento',
        'descr_desembargo',
        'data_decisao_embargo',
        'descr_observacao',
        'data_desembargo',
        'nome_imovel',
        'tipo_decisao',
        'sequencia',
      ], // v-t=array
      embargosFormatters: {
        data_embargo: 'date',
        data_insercao: 'date',
        data_desembargo: 'date',
        data_decisao_embargo: 'date',
      },

      trabCols: ['ano_acao', 'num_trab', 'estabelecimento', 'municipio', 'uf'], // v-t=array
      trabExtraCols: [
        'empregador',
        'num_doc',
        'cnae',
        'atividade',
        'localizacao',
        'data_inclusao',
      ], // v-t=array
      trabFormatters: {
        data_decisao: 'date',
        data_inclusao: 'date',
      },
    }
  },
  methods: {},
  computed: {
    ...mapState({
      entidade: (state) => state.entidades.detail,
      pendings: (state) => state.entidades.pending,
    }),
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/vars.scss';
h2 {
  text-align: left;
  border-bottom: solid 2px $color-text;
}
</style>

<template>
  <table class="table">
    <thead>
      <tr>
        <th v-for="c of cols" v-t="c" :key="c" />
      </tr>
    </thead>
    <tbody>
      <template v-for="row of rows" :key="row.id">
        <tr
          @click="toggleExpand(row.id)"
          :class="rows.indexOf(row) % 2 ? 'trow-even' : 'trow-odd'"
        >
          <td v-for="c of cols" :key="c">{{ format(c, row[c]) }}</td>
        </tr>
        <tr
          v-if="isExpanded(row.id)"
          :key="row.id + 'extra'"
          class="trow-expansion"
        >
          <td :colspan="cols.length">
            <dl>
              <template v-for="c of onlyExistent(extraCols, row)" :key="c">
                <dt>{{ $t(c) }}:</dt>
                <dd>{{ format(c, row[c]) }}</dd>
              </template>
            </dl>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
import { formatDate } from '@/utils'

export default {
  name: 'ExpTable',
  props: {
    rows: Array,
    cols: Array,
    extraCols: Array,
    formatters: Object,
  },
  data() {
    return {
      expanded: [],
    }
  },
  computed: {},
  methods: {
    toggleExpand(id) {
      if (!this.isExpanded(id)) this.expanded.push(id)
      else this.expanded.pop(id)
    },
    isExpanded(id) {
      return this.expanded.includes(id)
    },
    onlyExistent(cols, row) {
      return cols.filter((c) => row[c])
    },
    date(value) {
      return formatDate(value)
    },
    currency(value) {
      return this.$n(value, 'currency')
    },
    format(col, value) {
      // TODO: Usar uma tabela cumputed
      if (this.formatters) {
        let type = this.formatters[col]
        if (type) value = this[type](value)
      }
      return value === '' || value === null ? '-' : value
    },
  },
}
</script>

<style lang="scss">
@import '@/style/vars.scss';
.trow-odd,
.trow-even {
  cursor: pointer;
  transition: all 0.5s;
  &:hover,
  &:focus {
    color: $color-btn-hover;
    background-color: lighten($color-btn-hover, 45);
  }
}
dl {
  margin-top: 0;
}
</style>

<style lang="scss" scoped>
.table {
  width: 100%;
}
</style>

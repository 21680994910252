<template>
  <div>
    <strong>{{ entidade.nome }}</strong>
    <br />
    <span class="label">{{ numDocFormatted.label }}:</span>
    {{ numDocFormatted.num }}
    <br />
    {{ ufs }}
  </div>
</template>

<script>
import { formatNumDoc } from '@/utils'
export default {
  name: 'EntidadeMeta',
  props: {
    entidade: Object,
  },
  computed: {
    ufs() {
      if (this.entidade.ufs_list) {
        return this.entidade.ufs_list.join(' ').toUpperCase()
      } else {
        return Array.from(
          new Set(this.entidade.estabelecimentos.map((e) => e.uf))
        )
          .join(' ')
          .toUpperCase()
      }
    },
    numDocFormatted() {
      return formatNumDoc(this.entidade.num_doc)
    },
  },
}
</script>

<style lang="scss" scoped>
div {
  margin: 1rem;
}
strong {
  padding: 1rem;
}
</style>

<template>
  <div class="page">
    <div v-if="!pendings.relatedDocs && relacao">
      <header>
        <dados-entidade :entidade="relacao.entidades[0]" />
      </header>

      <section class="text-left" style="margin-top: 3rem">
        <div v-if="relacao.entidades.length > 1">
          Documentos da relação entre <b>{{ relacao.entidades[0].nome }}</b> e
          <b>{{ relacao.entidades[1].nome }}</b> (<documento-formatado
            :documento="relacao.entidades[1].num_doc"
          />).
        </div>

        <div v-if="relacao.fonte">
          <b>Fonte de Dados:</b> {{ $t(`fontes.${relacao.fonte.nome}.name`) }}
        </div>

        <div v-if="query.nome">
          Nome: <strong>{{ query.nome }}</strong>
        </div>

        <div v-if="query.mun">
          Município: <strong class="capitalize">{{ query.mun }}</strong>
        </div>

        <div>{{ $tc('n_found_docs', this.relacao.documentos.length) }}:</div>

        <ul>
          <li v-for="doc of relacao.documentos" :key="doc.id">
            <documento :content="doc.conteudo" :root="true" />
          </li>
        </ul>
      </section>
    </div>
    <spinner-anim v-else />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import spinnerAnim from '@/components/spinnerAnim'
import documento from '@/components/documento'
import dadosEntidade from '../components/dadosEntidade'
import documentoFormatado from '../components/documentoFormatado'

export default {
  name: 'Documentos',
  components: {
    dadosEntidade,
    documentoFormatado,
    spinnerAnim,
    documento,
  },
  data() {
    return {
      entidade: null,
      related: null,
    }
  },
  computed: {
    ...mapState({
      relacao: (state) => state.entidades.relatedDocs,
      pendings: (state) => state.entidades.pending,
      query: (state) => state.route.query,
    }),
  },
  mounted() {
    this.$watch(
      (vm) => [vm.query, vm.$auth.check()],
      ([query, authed]) => {
        if (query && authed) {
          this.listDocumentos({ params: query })
        }
      },
      {
        immediate: true,
        deep: true,
      }
    )
  },
  methods: {
    ...mapActions(['listDocumentos']),
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/vars.scss';

h1 {
  margin: 0;
}

.header {
  margin-bottom: 24px;
}

.text-left {
  text-align: left;
}

.page {
  margin-top: 0;
  margin-bottom: 0;
  height: unset;
  min-height: 100vh;
}

ul {
  list-style: none;
  padding: 0;
  text-align: right;
  display: flex;
  flex-flow: column;

  li::v-deep(span.btn) {
    font-size: 2.2rem;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    color: lightgray;
    background-color: $color-btn-hover;
    border-radius: 50%;
    margin-bottom: 8px;

    &:hover,
    &:focus,
    &.btn-close {
      color: $color-btn-hover;
      background-color: lightgray;
      border-radius: 50%;
      margin-bottom: 8px;
    }
  }

  li::v-deep(.document) {
    text-align: left;
    display: flex;
    justify-content: center;
    align-content: center;
    justify-self: center;
    box-shadow: 1px 1px 5px #0005;
    padding: 16px 24px 16px 6px;
    width: fit-content;
    margin: 8px auto;
  }
}
</style>

<template>
  <div class="flex-parent-center login-anchor">
    <div class="box">
      <div class="login-form">
        <input
          v-model="username"
          type="text"
          :placeholder="$t('username')"
          @keyup.enter="tryLogin"
        />
        <input
          v-model="password"
          type="password"
          :placeholder="$t('password')"
          @keyup.enter="tryLogin"
        />
        <button v-t="'login'" @click="tryLogin" />
      </div>
    </div>
  </div>
</template>

<script>
// v-t="'password'"
// v-t="'username'"

export default {
  name: 'Login',
  data() {
    return {
      username: '',
      password: '',
    }
  },
  methods: {
    async tryLogin() {
      var worked
      try {
        worked = await this.$auth.login({
          data: {
            username: this.username,
            password: this.password,
          },
        })
      } catch {
        worked = false
      }

      if (worked) {
        // Add background image only after authed.
        let bgClass = 'bg-img'
        let classes = document.getElementsByTagName('html')[0].classList
        if (this.$auth.check() && !classes.contains(bgClass)) {
          classes.add(bgClass)
        }
      } else {
        alert('erro...')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.org-logo {
  max-height: 5rem;
  margin: 1rem;
}
.login-anchor {
  min-height: 80vh;
}
h1 {
  text-shadow: 1px 1px 4px #000;
}
</style>

import { createAuth } from '@websanova/vue-auth/src/v3.js'
// import driverAuthBearer from '@websanova/vue-auth/src/drivers/auth/bearer.js'
import driverHttpAxios from '@websanova/vue-auth/src/drivers/http/axios.1.x.js'
import driverRouterVueRouter from '@websanova/vue-auth/src/drivers/router/vue-router.2.x.js'
import router from '@/router'

// function getUserObj(token) {
//   return { username: JSON.parse(atob(token.split('.')[1])).username }
// }

export default (app) => {
  app.use(
    createAuth({
      plugins: {
        http: app.axios,
        router,
      },
      drivers: {
        http: driverHttpAxios,
        auth: {
          request: function (req, token) {
            // add token to header
            this.drivers.http.setHeaders.call(this, req, {
              Authorization: 'Bearer ' + token,
            })
            // if is a refresh token request, add token to data (required by server)
            if (req.url.endsWith(this.options.refreshData.url)) {
              req.data = { token: this.token() }
            }
          },
          response: function (res) {
            var token = res.data.token
            if (typeof token === 'string') {
              return token
            }
            return null
          },
        },
        router: driverRouterVueRouter,
      },
      options: {
        // rolesKey: 'type',
        // notFoundRedirect: { name: 'user-account' },

        refreshData: { method: 'POST', interval: 8, enabled: true },
        // authRedirect: false,
        // logoutData: { redirect: false },
        loginData: { redirect: false, fetchUser: false },
        fetchData: {
          enabled: false,
          success: function () {
            console.log(app.auth)
            // var token = Vue.auth.token()
            // Vue.auth.user(getUserObj(token))
          },
        },
      },
    })
  )
}

import Vapi from 'vuex-rest-api'
import http from '@/http'

const entidade = new Vapi({
  axios: http,
  state: {
    searchList: [],
    detail: null,
    related: null,
    relatedDocs: null,
  },
})
  .get({
    action: 'searchEntidades',
    property: 'searchList',
    path: ({ text }) => `busca_entidade/${text}`,
  })
  .get({
    action: 'detailEntidade',
    property: 'detail',
    path: ({ id }) => `entidade_doc/${id}`,
  })
  .get({
    action: 'relatedByFonte',
    property: 'related',
    path: ({ id, fontId }) => `entidade_doc/${id}/fonte/${fontId}`,
  })
  .get({
    action: 'listDocumentos',
    property: 'relatedDocs',
    path: (query) => {
      delete query.params
      delete query.data
      return 'documentos?' + new URLSearchParams(query).toString()
    },
  })
  .getStore()

export default entidade

import i18n from '@/i18n'
import axios from 'axios'
import { cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions'

// enhance the original axios adapter with throttle and cache enhancer
export const http = axios.create({
  // headers: { 'Cache-Control': 'no-cache' },
  adapter: throttleAdapterEnhancer(
    cacheAdapterEnhancer(axios.defaults.adapter)
  ),
})

// tries to format a document
export const formatNumDoc = (d, convertToBase = true) => {
  var label = 'documento'
  var num = d
  if (d.length === 14) {
    if (convertToBase) {
      d = calcCNPJLastDigits(d.slice(0, 8) + '0001')
    }
    label = 'CNPJ'
    num = `${d.slice(0, 2)}.${d.slice(2, 5)}.${d.slice(5, 8)}/${d.slice(
      8,
      12
    )}-${d.slice(12, 14)}`
  } else if (d.length === 11) {
    label = 'CPF'
    num = `${d.slice(0, 3)}.${d.slice(3, 6)}.${d.slice(6, 9)}-${d.slice(9, 12)}`
  }
  return {
    label: transNs('num_doc__labels', label),
    num,
  }
}

// eslint-disable-next-line
String.prototype.capitalize = function () {
  return this.replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase()
  })
}

// calculate the last 2 digits of a CNPJ
export const calcCNPJLastDigits = (d) => {
  d = d.split('').map((i) => parseInt(i))
  let sum = (acc, v, i) => acc + v * d[i]
  let w = [6, 7, 8, 9, 2, 3, 4, 5, 6, 7, 8, 9]
  let r1 = w.reduce(sum, 0) % 11
  let d1 = r1
  if (d1 > 9) d1 = 0
  w.unshift(5)
  d.push(d1)
  let r2 = w.reduce(sum, 0) % 11
  let d2 = r2
  if (d2 > 9) d2 = 0
  return d.concat(d2).join('')
}

export function formatDate(value) {
  return i18n.global.d(new Date(value), 'short')
}

// Attempt to translate using a namespace.
// If the ns.key is not found, return the key instead of the ns.key,
// contrary to what Vue-i18n does.
export function transNs(ns, key) {
  let nskey = ns + '.' + key
  let value = i18n.global.t(nskey)
  if (nskey === value) return key
  else return value
}

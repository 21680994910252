<template>
  <div class="content-width page box">
    <h2 v-t="'website_title'" />
    <p v-t="'search_entidades'" />
    <div class="flex-parent-center search-bar">
      <input type="text" v-model="searchText" @keyup.enter="search" />
      <button @click="search">
        <!-- <svg-icon name="search" /> -->
      </button>
    </div>
    <div v-if="!pendings.searchList">
      <div v-for="ent of entidades.searchList" :key="ent.num_doc">
        <router-link :to="{ name: 'entidade', params: { entId: ent.num_doc } }">
          <entidade-meta :entidade="ent" />
        </router-link>
      </div>
      <p v-if="searchDone && !entidades.searchList.length" v-t="'not_found'" />
    </div>
    <spinner-anim v-else />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import entidadeMeta from '@/components/entidadeMeta'
import spinnerAnim from '@/components/spinnerAnim'

export default {
  name: 'home',
  components: {
    entidadeMeta,
    spinnerAnim,
  },
  data() {
    return {
      searchText: '',
      searchDone: false,
    }
  },
  methods: {
    ...mapActions(['searchEntidades']),
    search() {
      this.searchDone = true
      this.searchEntidades({ params: { text: this.searchText } })
    },
  },
  computed: mapState({
    entidades: (state) => state.entidades,
    pendings: (state) => state.entidades.pending,
  }),
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: #fff;
}
.search-bar {
  padding-bottom: 2rem;
  flex-flow: row;
  input {
    margin: 0;
    box-sizing: border-box;
    text-align: left;
  }
  button {
    width: 4rem;
    height: 1.95rem;
    box-sizing: border-box;
  }
  .svg-icon {
    width: 100%;
    height: 100%;
  }
}
.content-width {
  width: 600px;
}
</style>

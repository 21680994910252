<template>
  <div v-if="entidade">
    <h1>{{ entidade.nome }}</h1>
    <small>
      <documento-formatado :documento="documento" />
    </small>
  </div>
</template>

<script>
import documentoFormatado from './documentoFormatado'

export default {
  name: 'dadosEntidade',
  components: { documentoFormatado },
  props: {
    entidade: {
      required: true,
    },
  },
  computed: {
    documento: function () {
      return this.entidade.num_doc
    },
  },
}
</script>

<style scoped>
h1 {
  margin: 0;
}

small {
  display: block;
  font-size: 10pt;
}
</style>

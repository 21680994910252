import { createI18n } from 'vue-i18n'

const sepChar = '|'

// Recursively returns all the keys in an object.
function getKeyList(obj, keys = [], prefix) {
  for (let [key, value] of Object.entries(obj)) {
    if (typeof value === 'object') {
      getKeyList(value, keys, key)
    } else {
      keys.push((prefix ? prefix + sepChar : '') + key)
    }
  }
  return keys
}

// Fills an object with missing locale messages.
// Essa função serve para pegar as strings estão definidas no locale "en" e
// faltantes no "pt-BR" e preencher nesse último usando o próprio valor chave
// (engorda: "engorda"). É só para não ter o trabalho de ter que ir em "pt-BR"
// e ficar difinindo essas strings redundantes.
function fillMissing(messages, missing) {
  for (let key of missing) {
    let paths = key.split(sepChar)
    let str = paths.pop()
    let curr = messages
    for (let path of paths) {
      if (curr[path] === undefined) curr[path] = {}
      curr = curr[path]
    }
    curr[str] = str
  }
}

function loadLocaleMessages() {
  const locales = require.context(
    './locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  )
  const messages = {}
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })

  let baseLoc = 'pt-BR'
  let baseKeys = getKeyList(messages[baseLoc])
  let fillBase = true
  for (let loc in messages) {
    if (loc !== baseLoc) {
      let missing
      let keys = getKeyList(messages[loc])

      missing = keys.filter((key) => !baseKeys.includes(key))
      if (missing.length) {
        if (fillBase) {
          fillMissing(messages[baseLoc], missing)
        } else {
          console.log(baseLoc, 'missing:\n' + missing.join('\n'))
        }
      }

      missing = baseKeys.filter((key) => !keys.includes(key))
      if (missing.length) console.log(loc, 'missing:\n' + missing.join('\n'))
    }
  }

  return messages
}

const dateFormat = {
  short: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  },
}

const numFormat = {
  currency: {
    useGrouping: true,
    minimumFractionDigits: 2,
  },
}

export default createI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'pt-BR',
  // fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
  preserveDirectiveContent: true,
  datetimeFormats: {
    'pt-BR': dateFormat,
    en: dateFormat,
  },
  numberFormats: {
    'pt-BR': numFormat,
    en: numFormat,
  },
})

<template>
  <div class="page">
    <div v-if="!pendings.related && related">
      <header>
        <dados-entidade :entidade="related.entidade" />
      </header>

      <section>
        <strong>Fonte de Dados: </strong>
        {{ $t(`fontes.${related.fonte.nome}.name`) }}
        <br />
        <br />
        <strong>Descrição da Fonte: </strong>
        {{ $t(`fontes.${related.fonte.nome}.descr`) }}

        <h2>Entidades Vinculadas</h2>
        <ul>
          <li v-for="ent of related.entidades" :key="ent.num_doc">
            {{ ent.nome }}

            [<router-link
              :to="{ name: 'entidade', params: { entId: ent.num_doc } }"
              >Perfil do produtor</router-link
            >] [<router-link
              :to="{
                name: 'documentos',
                query: { ents: entId + ',' + ent.num_doc, fonte: fontId },
              }"
              >Veja os documentos</router-link
            >]
          </li>
        </ul>
      </section>
    </div>
    <spinner-anim v-else />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import spinnerAnim from '@/components/spinnerAnim'
import { formatNumDoc } from '@/utils'
import dadosEntidade from '@/components/dadosEntidade'

export default {
  name: 'EntidadesRelacionadas',
  components: {
    dadosEntidade,
    spinnerAnim,
  },
  computed: {
    ...mapState({
      related: (state) => state.entidades.related,
      pendings: (state) => state.entidades.pending,
      entId: (state) => state.route.params.entId,
      fontId: (state) => parseInt(state.route.params.fontId),
    }),
    numDocFormatted() {
      return formatNumDoc(this.related.entidade.num_doc)
    },
  },
  mounted() {
    this.$watch(
      (vm) => [vm.entId, vm.fontId, vm.$auth.check()],
      ([entId, fontId, authed]) => {
        if (entId && fontId && authed) {
          this.relatedByFonte({ params: { id: entId, fontId: fontId } })
        }
      },
      {
        immediate: true,
        deep: true,
      }
    )
  },
  methods: {
    ...mapActions(['relatedByFonte']),
  },
}
</script>

<style lang="scss" scoped>
h1 {
  margin: 0;
}

section {
  text-align: left;
  margin-top: 3rem;
}

.page {
  margin-top: 0;
  margin-bottom: 0;
  height: unset;
  min-height: 100vh;
}
</style>

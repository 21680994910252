import { createRouter, createWebHistory } from 'vue-router'
import Login from '@/views/Login.vue'
import Busca from '@/views/Busca.vue'
import Fiscalizacoes from '@/views/Fiscalizacoes.vue'
import Entidade from '@/views/Entidade.vue'
import EntidadesRelacionadas from './views/EntidadesRelacionadas'
import Documentos from '@/views/Documentos.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Busca,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/entidade/:entId',
    name: 'entidade',
    component: Entidade,
  },
  {
    path: '/entidade/:entId/fiscalizacoes',
    name: 'fiscalizacoes',
    component: Fiscalizacoes,
  },
  {
    path: '/entidade/:entId/fonte/:fontId',
    name: 'fontes-entidade',
    component: EntidadesRelacionadas,
  },
  {
    path: '/documentos',
    name: 'documentos',
    component: Documentos,
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
]

export default createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

// export default (app) => {
//   app.router = router
//   app.use(router)
// }

<template>
  <div class="page">
    <div v-if="!pendings.detail && entidade">
      <header class="flex-parent-center">
        <!-- <svg-icon
          v-for="tipo of tipos"
          :key="tipo"
          :name="tipo"
          width="100"
          height="100"
        /> -->
        <dados-entidade :entidade="entidade" />
      </header>

      <div class="two-cols">
        <div>
          <!-- Left Col -->
          <h2>
            {{ $tc('map_header_text', entidade.estabs_de_docs.length) }}
          </h2>
          <entidade-mapa :entidade="entidade" />
        </div>

        <div>
          <!-- Right Col -->
          <div>
            <h2 v-t="'fiscal_header_text'" />

            <ul>
              <!-- multas e embargos -->
              <li>{{ ibamaText }}</li>

              <!-- trabalho escravo -->
              <li>{{ trabText }}</li>
            </ul>

            <router-link v-if="entidade.multas.length ||
              entidade.embargos.length ||
              entidade.trab.length
              " v-t="'more_details'" :to="{
    name: 'fiscalizacoes',
    params: { entId: entidade.num_doc },
  }" />

            <br>
            <br>
            <a :href="'https://api.rbmonitor.org/mapa?entidade=' + entidade.num_doc" v-t="'props_map'" />

            <h2 v-t="'connections_header_text'" />

            <ul>
              <li v-for="fonte of entidade.fontes_vinculos" :key="fonte.id">
                <router-link :to="{
                  name: 'fontes-entidade',
                  params: {
                    entId: entidade.num_doc,
                    fontId: fonte.id,
                  },
                }">
                  {{ $t(`fontes.${fonte.nome}.name`) }}
                </router-link>
              </li>
            </ul>

            <!-- <br />
            <br />
            <spinner-anim v-if="downloadingDump" />
            <div v-else>
              <p v-t="'download_dump'" style="margin-bottom: 0" />
              <button class="btn" @click="downloadDump('csv')">CSV</button>
              <button class="btn" @click="downloadDump('xlsx')">XLSX</button>
              <button class="btn" @click="downloadDump('json')">JSON</button>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <spinner-anim v-else />
  </div>
</template>

<script>
import http from '@/http'
import { mapActions, mapState } from 'vuex'
import { formatNumDoc } from '@/utils'
import entidadeMapa from '@/components/entidadeMapa'
import spinnerAnim from '@/components/spinnerAnim'
import dadosEntidade from '@/components/dadosEntidade'

export default {
  name: 'Entidade',
  components: {
    dadosEntidade,
    entidadeMapa,
    spinnerAnim,
  },
  data() {
    return {
      downloadingDump: false,
    }
  },
  computed: {
    ...mapState({
      entidade: (state) => state.entidades.detail,
      pendings: (state) => state.entidades.pending,
      entId: (state) => state.route.params.entId,
    }),
    numDocFormatted() {
      return formatNumDoc(this.entidade.num_doc)
    },
    // tipos() {
    //   let tipos = new Set()
    //   for (let e of this.entidade.estabelecimentos) {
    //     for (let tipo of e.tipo.split(',')) {
    //       tipos.add(tipo)
    //     }
    //   }
    //   return tipos
    // },
    ibamaText() {
      if (this.entidade.multas.length || this.entidade.embargos.length) {
        return this.$t('num_embargos_multas_text', [
          this.$tc('embargos', this.entidade.embargos.length),
          this.$tc('multas_ambientais', this.entidade.multas.length),
        ])
      } else {
        return this.$t('ibama_text_no')
      }
    },
    trabText() {
      if (this.entidade.trab.length) {
        return this.$t('have_trab_escr')
      } else {
        return this.$t('no_trab_escr')
      }
    },
    fornecedoresDiretosText() {
      return this.$t('fornecedores_diretos_text', [
        this.$tc(
          'num_doc_text',
          this.entidade.network_report.fornecedores.fiscalizacoes.multas
        ),
        this.entidade.network_report.fornecedores.fiscalizacoes.embargos ||
        this.$t('none'),
        this.entidade.network_report.fornecedores.fiscalizacoes.trab ||
        this.$t('none'),
      ])
    },
  },
  methods: {
    ...mapActions(['detailEntidade']),
    async downloadDump(type) {
      if (!this.downloadingDump) {
        this.downloadingDump = true
        try {
          const response = await http.get(
            `entidade/${this.entidade.num_doc}.${type}`,
            {
              responseType: 'blob',
            }
          )
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: response.headers['content-type'],
            })
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${this.entidade.num_doc}.${type}`)
          document.body.appendChild(link)
          link.click()
        } catch (e) {
          alert(e)
        }
        this.downloadingDump = false
      }
    },
  },
  mounted() {
    this.$watch(
      (vm) => [vm.entId, vm.$auth.check()],
      ([newValue, authed]) => {
        if (newValue && authed) {
          this.detailEntidade({ params: { id: newValue } })
        }
      },
      {
        immediate: true,
        deep: true,
      }
    )
  },
}
</script>

<style lang="scss" scoped>
h1 {
  margin: 0;
}

.svg-icon {
  margin: 1rem;
}

.page {
  margin-top: 0;
  margin-bottom: 0;
  height: unset;
  min-height: 100vh;
}

.two-cols {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
}

small {
  font-size: 10pt;
}

ul {
  text-align: left;
}

.btn {
  margin: 0.5rem;
  width: auto;
}
</style>

<template>
  <div class="map-anchor">
    <l-map ref="map" :zoom="zoom" :center="center">
      <l-control-layers />
      <l-tile-layer
        v-for="tileProvider in tileProviders"
        :key="tileProvider.name"
        :name="tileProvider.name"
        :visible="tileProvider.visible"
        :url="tileProvider.url"
        :attribution="tileProvider.attribution"
        layer-type="base"
      />
      <l-feature-group ref="featureGroup">
        <l-polygon
          v-for="mun of municipios"
          :key="mun.normalized_key"
          :lat-lngs="mun.polygon"
          color="black"
          :fill="true"
        >
          <l-popup>
            <h2>{{ mun.name.capitalize() }} - {{ mun.uf.toUpperCase() }}</h2>
            <ul>
              <li v-for="estab of mun.estabs" :key="estab.nome">
                <router-link
                  :to="{
                    name: 'documentos',
                    query: {
                      ents: entidade.num_doc,
                      nome: estab.nome,
                      mun: estab.municipio.name,
                    },
                  }"
                >
                  {{ estab.nome || 'unnamed' }}
                </router-link>
              </li>
            </ul>
          </l-popup>
        </l-polygon>
      </l-feature-group>
    </l-map>
  </div>
</template>

<script>
import {
  LMap,
  LTileLayer,
  LPolygon,
  LPopup,
  LFeatureGroup,
  LControlLayers,
} from '@vue-leaflet/vue-leaflet'

export default {
  name: 'EntidadeMapa',
  components: {
    LMap,
    LTileLayer,
    LPolygon,
    LPopup,
    LFeatureGroup,
    LControlLayers,
  },
  props: {
    entidade: { type: Object, default: null },
  },
  data() {
    return {
      zoom: 3,
      center: [-14, -60],
      tileProviders: [
        {
          name: 'Vetorial',
          visible: true,
          attribution:
            '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        },
        {
          name: 'Satélite',
          visible: false,
          url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
          attribution:
            'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
        },
      ],
    }
  },
  computed: {
    estabs() {
      return this.entidade.estabs_de_docs
    },
    municipios() {
      let muns = {}
      for (let e of this.estabs) {
        let m = e.municipio
        if (!m) {
          console.log('Estabelecimento sem município:', e)
          continue
        }
        if (!muns[m.normalized_name]) {
          muns[m.normalized_name] = m
          m.estabs = []
          m.color = 'blue'
        }
        muns[m.normalized_name].estabs.push(e)
      }
      return Object.values(muns)
    },
  },
  mounted() {
    this.$nextTick(function () {
      let refs = this.$refs
      setTimeout(function () {
        refs.map.leafletObject.fitBounds(
          refs.featureGroup.leafletObject.getBounds().pad(0.5),
          { maxZoom: 7 }
        )
      }, 500)
    })
  },
}
</script>

<style lang="scss" scoped>
.map-anchor {
  height: 30rem;
}
ul {
  padding-left: 0.5rem;
}
</style>

<template>
  <!-- <div @click="toggleExpand"> -->
  <span
    v-if="root"
    @click="toggleExpand"
    class="btn"
    :class="{ 'btn-open': !expanded, 'btn-close': expanded }"
    ><span v-if="expanded">-</span><span v-else>+</span></span
  >
  <div v-if="!root || expanded" class="inline" :class="{ document: root }">
    <strong v-if="['string', 'number'].includes(typeof content)">
      {{ content }}
    </strong>
    <ul v-else-if="Array.isArray(content)">
      <li v-for="(v, k) of content" :key="k" class="list-item">
        <documento :content="v" />
      </li>
    </ul>
    <ul v-else-if="typeof content === 'object'">
      <li v-for="(v, k) in content" :key="k">
        {{ k }}: <documento :content="v" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Documento',
  props: {
    content: undefined,
    root: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expanded: false,
    }
  },
  computed: {},
  methods: {
    toggleExpand() {
      this.expanded = !this.expanded
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/vars.scss';
.btn {
  cursor: pointer;
  transition: all 0.5s;
  &:hover,
  &:focus {
    color: $color-btn-hover;
    background-color: lighten($color-btn-hover, 45);
  }
}
dl {
  margin-top: 0;
}
ul {
  list-style: none;
  padding-left: 1rem;
}
.inline {
  display: inline;
}
.list-item {
  margin-bottom: 1rem;
}
</style>

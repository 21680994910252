import axios from 'axios'
import { cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions'

// enhance the original axios adapter with throttle and cache enhancer
export default axios.create({
  // headers: { 'Cache-Control': 'no-cache' },
  baseURL: process.env.VUE_APP_API_URL,
  adapter: throttleAdapterEnhancer(
    cacheAdapterEnhancer(axios.defaults.adapter)
  ),
})

// var http = axios

// http.defaults.baseURL = process.env.VUE_APP_API_URL

// export default (app) => {
//   app.axios = http
//   app.$http = http

//   app.config.globalProperties.axios = http
//   app.config.globalProperties.$http = http
// }
